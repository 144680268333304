<template>
  <NuxtLink
    :to="articleRouteObject"
    class="relative flex md:flex-col shadow-teaser rounded-xl md:overflow-hidden p-3 md:p-0 border md:border-none border-grey-200 group"
  >
    <B2CArticleTeaserImage
      :article-image="props.articleImage"
      :article-title="props.articleTitle"
      :is-video="props.isVideo"
      :is-framed="props.isFramed"
      :preload="props.preload"
      :lazy="props.lazy"
    />
    <div
      class="w-full md:px-6 md:py-6 ml-3 md:ml-0 rounded-xl rounded-t-none md:border md:border-t-0 md:border-grey-200"
    >
      <B2CArticleTeaserLabel
        class="mb-1.5"
        :type="props.type"
        :article="props.article"
        :is-framed="props.isFramed"
        :is-video="props.isVideo"
      />

      <h2
        class="text-[13px]/4 md:text-2xl/[30px] font-bold font-sans text-[#000000] -tracking-[0.005em] group-hover:underline decoration-1 md:decoration-2"
      >
        {{ props.articleTitle }}
      </h2>
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
import { ContentArticle } from '~/typesManual/content_api/article'

const indexStore = useIndexStore()
const props = defineProps<{
  article: ContentArticle
  articleImage: string
  articleTitle: string
  type: string
  isVideo?: boolean
  isFramed?: boolean
  preload?: boolean
  lazy?: boolean
}>()

const articleRouteObject = computed(() => {
  return {
    name: indexStore.currentPaperSlug ? 'paper_article' : 'article',
    params: indexStore.currentPaperSlug
      ? {
          paper: indexStore.currentPaperSlug,
          id: props.article.UrlKey || props.article.urlKey,
        }
      : { id: props.article.UrlKey || props.article.urlKey },
  }
})
</script>
