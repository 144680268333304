<template>
  <NuxtLink
    :to="{
      name: 'article',
      params: {
        id: article.UrlKey ?? article.urlKey,
      },
    }"
    class="grid p-3 md:p-0 grid-cols-[auto_1fr] items-start md:grid-rows-[auto_1fr] md:grid-cols-1 md:items-center bg-white border border-gray-300 shadow-teaser rounded-xl group"
  >
    <div
      class="md:bg-gray-100 md:rounded-xl md:py-6 md:flex md:items-center md:justify-center"
    >
      <NuxtImg
        class="h-20 w-20 md:h-44 md:w-44 rounded-xl"
        :src="
          article.Podcast
            ? config.public.site.legacydomain + article.Podcast?.image
            : podcastImage // Fallback to article image if no podcast image
        "
      />
    </div>
    <div
      class="flex justify-around gap-2 items-center md:items-center md:p-4 ml-3 md:ml-0"
    >
      <div>
        <B2CArticleTeaserLabel
          type="podcast"
          :article="props.article"
          class="mb-1.5"
        />
        <h2
          class="text-sm md:text-2xl/[30px] font-bold font-sans text-[#000000] -tracking-[0.005em] group-hover:underline decoration-1 md:decoration-2"
        >
          {{ podcastTitle }}
        </h2>
      </div>
      <div
        class="rounded-full cursor-pointer bg-red flex items-center justify-center shrink-0 h-8 w-8 md:h-10 md:w-10 mr-2"
      >
        <img
          class="w-4 h-4 md:w-5 md:h-5 transform ml-0.5"
          src="@/assets/icons/play.svg?url"
          alt="Play"
        />
      </div>
    </div>
  </NuxtLink>
</template>
<script setup lang="ts">
import { ContentArticle } from '~/typesManual/content_api/article'

const props = defineProps<{
  article: ContentArticle
}>()

const config = useRuntimeConfig()

const podcastTitle = computed(
  () =>
    props.article.Headline ||
    props.article.HeadLine ||
    props.article.headline ||
    props.article.Title
)
const podcastImage = computed(
  () =>
    props.article.ImageUrl || props.article.imageUrl || props.article.imageurl
)
</script>
